// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;

  font-family: 'BMHANNAPro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

div {
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type='password'] {
  font-family: '굴림';
}

::placeholder {
  font-family: 'BMHANNAPro';
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0) scale(1);
  }
  40% {
    transform: translateY(-10px) scale(1.2);
  }
  60% {
    transform: translateY(-5px) scale(1.1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;;EAET;;gCAE8B;EAC9B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;EACE;aACW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;;IAEE,wBAAwB;EAC1B;EACA;;;;;IAKE,2BAA2B;EAC7B;EACA;;;;IAIE,0BAA0B;EAC5B;AACF;;AAEA;EACE;;;;;IAKE,iCAAiC;EACnC;EACA;IACE,uCAAuC;EACzC;EACA;IACE,sCAAsC;EACxC;AACF","sourcesContent":["body {\n  margin: 0;\n\n  font-family: 'BMHANNAPro', -apple-system, BlinkMacSystemFont, 'Segoe UI',\n    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',\n    'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nul li {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n}\n\ndiv {\n  padding: 0;\n  margin: 0;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\ninput[type='password'] {\n  font-family: '굴림';\n}\n\n::placeholder {\n  font-family: 'BMHANNAPro';\n}\n\n@keyframes shake {\n  0%,\n  100% {\n    transform: translateX(0);\n  }\n  10%,\n  30%,\n  50%,\n  70%,\n  90% {\n    transform: translateX(-5px);\n  }\n  20%,\n  40%,\n  60%,\n  80% {\n    transform: translateX(5px);\n  }\n}\n\n@keyframes bounce {\n  0%,\n  20%,\n  50%,\n  80%,\n  100% {\n    transform: translateY(0) scale(1);\n  }\n  40% {\n    transform: translateY(-10px) scale(1.2);\n  }\n  60% {\n    transform: translateY(-5px) scale(1.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
